import './call.scss';

function Call() {
  return (
    <a href='tel:+375447345474' className='call'>
      <div className='icon'/>
    </a>
  )
}

 export default Call