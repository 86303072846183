import './help.scss';

function Help() {
  return(
    <section className='help' id='help'>
      <h2> Виды помощи</h2>
      <p>С учетом продолжительности работы в уголовно-правовой сфере и наличия практического опыта, основной отраслью права, в которой я осуществляю адвокатскую деятельность, является УГОЛОВНАЯ . Она включает:</p>
      <ul>
        <li>устные и письменные консультации на конфиденциальной основе. </li>
        <li>изучение материалов проверок и уголовных дел. </li>
        <li>составление запросов, заявлений, ходатайств, жалоб, исковых заявления, возражений и их направление (предоставление). </li>
        <li>Составление апелляционных, кассационных и надзорных жалоб на приговоры суда. </li>
        <li>Участие в качестве защитника на стадиях проведения проверок, предварительного расследования, в судах. </li>
        <li>Участие в качестве адвоката свидетеля на стадиях предварительного расследования и в судах. </li>
        <li>Представление интересов потерпевшего на стадиях предварительного расследования и в судах. </li>
      </ul>
      <p>Кроме того, формируя линию защиты и представительство по уголовным делам, осуществляю сбор и представление доказательств и иных сведений для защиты прав подозреваемых, обвиняемых, потерпевших, гражданских истцов, гражданских ответчиков. Оказываю им юридическую помощь путем опроса физических лиц, а также имею право запрашивать справки, характеристики и иные документы или их копии. Запрашиваю с согласия подзащитного мнения специалистов для разъяснения возникающих в связи с осуществлением защиты вопросов, требующих специальных знаний.</p>
      <p>Осуществляю высококвалифицированную защиту и представительство по всем категориям уголовных дел вне зависимости от направленности и тяжести совершенного общественно опасного деяния. Имею большой опыт практической работы с преступлениями, связанными с незаконным оборотом наркотических средств, психотропных веществ, прекурсоров и их аналогов (статья 328 УК РБ).</p>
      <h3>Другие виды помощи</h3>
      <ul>
        <li>АМНИСТИЯ</li>
        <li>АДМИНИСТРАТИВНОЕ ПРАВО</li>
        <li>АВТО И ГАИ</li>
      </ul>
      <h3>Возможно оказание других видов юридической помощи:</h3>
      <ul>
        <li>консультации и разъяснения по юридическим вопросам;</li>
        <li>составление заявлений, жалоб и других документов правового характера;</li>
        <li>представление интересов клиентов в судах, в том числе на стадии исполнения судебных постановлений, а также в
                государственных органах, иных организациях, в том числе их органах управления, и перед физическими лицами;</li>
        <li>участие в административном процессе в качестве защитника, представителя потерпевшего, иных физических или юридических лиц, являющихся участниками административного процесса;</li>
        <li>проведение правовой оценки документов и деятельности;</li>
        <li>ведение правовой работы по обеспечению хозяйственной и иной деятельности;</li>
        <li>совершение от имени и в интересах клиентов юридически значимых действий в пределах полномочий, предоставленных клиентом и законодательством;</li>
        <li>оказание иных видов юридической помощи.</li>
      </ul>
    </section>
  )
}

export default Help;