import './salary.scss';

function Salary() {
  return (
    <section className='salary' id='salary'>
      <h2>Гонорар</h2>
      <p>Порядок и размер оплаты юридической помощи, оказываемой адвокатом клиенту (далее - гонорар), определяются договором на оказание юридической помощи и являются его существенными условиями.</p>
      <p>Размер гонорара и порядок его определения (фиксированная сумма, оплата за день участия в предварительном следствии или в суде, оплата согласованной почасовой ставки, доплата за позитивный результат по делу и т.д.) определяются по договоренности между адвокатом и клиентом и указываются в договоре на оказание юридической помощи.</p>
      <p>Размер гонорара за оказываемые услуги может изменяться в случаях: оказания юридической помощи за пределами г. Минска, оказания юридической помощи в выходные и праздничные дни, оказания юридической помощи нескольким клиентам по одному делу, срочность оказания юридической помощи.</p>
    </section>
  )
}

export default Salary;