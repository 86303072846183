import './hook.scss';

function Hook() {
  return (
    <div className='hook'>
      <h1>Павел Владимирович Грицук</h1>
      <p> Aдвокат по уголовным делам</p>
      <h5>15 лет успешной практики</h5>
  </div>
  )
}

export default Hook;