function About() {
  return (
    <section className='about' id='about'>
      <h2>Немного обо мне</h2>
      <p>Я, Грицук Павел Владимирович, завершил обучение на следственно-экспертном факультете Академии Министерства внутренних дел Республики Беларусь по специальности «Правоведение».</p>
      <p>С 2009 по 2012 год служил в органах предварительного расследования Министерства внутренних дел Республики Беларусь в должности следователя.</p>
      <p>С 2012 по 2014 год занимал должность старшего следователя в подразделении Следственного комитета Республики Беларусь.</p>
      <p>С 2014 года предоставлял юридическую помощь в качестве юрисконсульта юридическим лицам.</p>
      <p>С 2014 по 2015 год проходил стажировку в юридической консультации Советского района города Минска.</p>
      <p>В 2015 году прошел аттестацию и получил свидетельство юриста.</p>
      <p>В настоящее время осуществляю адвокатскую деятельность на территории Республики Беларусь. Являюсь членом Минской городской коллегии адвокатов. Лицензия № 02240/2638 выдана Министерством юстиции.</p>
    </section>
  )
}

export default About;